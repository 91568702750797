import React from "react";
import { graphql } from "gatsby";

import Layout from "../../components/Layout";
import SEO from "../../components/seo";
import Header from "../../components/Header";
import Breadcrumb from "../../components/Breadcrumb";
import ContactForm from "../../components/ContactForm";
import Branches from "../../components/Branches";
import MasterContact from "../../components/MasterContact";

import { Container, Flex, Wrapper } from "../../style/Grid";
import { Heading } from "../../style/Heading";
import { Image } from "../../style/Image";
import { Text } from "../../style/Text";

const ContactTemplate = ({
  data: {
    wp: {
      page: {
        title,
        uri,
        featuredImage,
        seo: { metaDesc },
      },
      categories: { nodes: categories },
    },
  },
}) => {
  const breadcrumbs = [
    {
      label: title,
      link: uri,
    },
  ];

  const situations = [];

  categories.map(category => {
    if (category.pages.nodes[0]) situations.push(category.pages.nodes[0]);
  });

  return (
    <Layout>
      <SEO title={title} description={metaDesc} />
      <Header variant="light" />

      <Wrapper>
        <Container flexDirection="column">
          <Flex flexDirection="column" mb={[3, null, 5]}>
            <Breadcrumb breadcrumbs={breadcrumbs} />
            <Heading as={"h1"} variant={"h1"} uppercase>
              Kontakty
            </Heading>
          </Flex>
        </Container>
      </Wrapper>

      <MasterContact image={featuredImage.node} />

      <Wrapper id={"pobocky"} pt={4}>
        <Container>
          <Flex alignItems={"center"} flexDirection={"column"} width={1} mb={72}>
            <Heading as={"h2"} fontSize={5} lineHeight={"38px"} uppercase mb={20} textAlign={"center"}>
              CHCETE NAJÍT POBOČKU?
            </Heading>
            <Text textAlign={"center"} fontSize={2} lineHeight={"20px"} maxWidth={["100%", "80%"]}>
              KAPITOL má obchodní zastoupení ve&nbsp;všech regionech České republiky.
              <br />
              Kromě zde uvedených obchodních zastoupení funguje v&nbsp;městech a&nbsp;obcích ČR dalších také mnoho kanceláří KAPITOLu.
            </Text>
          </Flex>
        </Container>
      </Wrapper>

      <Branches />

      <Wrapper mt={[60, 80]}>
        <Container>
          <Flex mb={[4, null, null, 5]} width={1} flexDirection={["column", null, null, "row"]} alignItems="flex-start">
            <Flex flexGrow={1} pr={5} display={["none", null, null, "block"]} maxWidth={500}>
              <Image
                display="block"
                boxShadow="one"
                borderRadius="1"
                width={1}
                maxWidth={387}
                src={require("../../assets/images/contact/contact-hero.jpg").default}
              />
            </Flex>

            <Flex flexGrow={1} width={"100%"} maxWidth={["100%", null, null, "690px"]}>
              <ContactForm type="general" title="NAPIŠTE NÁM" situations={situations} />
            </Flex>
          </Flex>
        </Container>
      </Wrapper>
    </Layout>
  );
};

export default ContactTemplate;

export const pageQuery = graphql`
  query CONTACT_PAGE_QUERY {
    wp {
      page(id: "kontakt", idType: URI) {
        title
        uri
        featuredImage {
          node {
            sourceUrl(size: MEDIUM_LARGE)
          }
        }
        seo {
          metaDesc
        }
      }
      categories(first: 9999, after: null) {
        nodes {
          pages {
            nodes {
              id
              title
            }
          }
        }
      }
    }
  }
`;

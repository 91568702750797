import React from "react";
import { Box, Container, Flex, Wrapper } from "../../style/Grid";
import { Heading } from "../../style/Heading";
import { Text } from "../../style/Text";
import Link from "../Link";
import { Button } from "../../style/Button";
import { contact, URLS } from "../../const/const";
import { Image } from "../../style/Image";
import Icon from "../../style/Icon";
import { PhoneHeader } from "../../assets/Svg";

const MasterContact = ({ image }) => (
  <Wrapper bg="panelPrimary" mb={5} pt={4} pb={[0, null, 4]}>
    <Container>
      <Flex width={1}>
        <Flex maxWidth="800px" flexGrow={1} mr={[0, null, "20px"]} flexDirection={["column", null, null, "row"]}>
          <Box flexGrow={1} width={1} borderRight={["none", null, null, "1px solid #80B2B8"]} mr={[0, null, 3]} mb={[4, null, 0]} pr={[0, null, 3]}>
            <Box maxWidth={390}>
              <Heading as={"h2"} fontSize={[4, null, 5]} mb={3} fontWeight="light" lineHeight={0} uppercase>
                Jsme tu pro&nbsp;vás
              </Heading>

              <Text fontSize={3} mb={2} lineHeight={1}>
                Naše klientská linka vám poskytne široké spektrum užitečných informací o&nbsp;našich službách.
              </Text>

              <Text mb={3}>Jsme Vám k&nbsp;dispozici v Po-Pá 8:00-16:00</Text>

              <Flex pb={42} mb={26} as={Link} href={`tel:${contact.phoneLink}`} alignItems={"center"} variant={"borderDividerBottom"}>
                <Image width={[24, null, 34]} mr={2} src={PhoneHeader} />
                <Text
                  as="span"
                  color="cta"
                  fontSize={[36, null, 44]}
                  lineHeight={["42px", null, "52px"]}
                  fontWeight="300"
                  dangerouslySetInnerHTML={{ __html: contact.phone }}
                />
              </Flex>

              <Text lineHeight={1} mb={4}>
                Pokud vám více vyhovuje písemný kontakt, pošlete nám zprávu nebo nám zanechte telefon.
              </Text>

              <Button display="inline-block" as={Link} href={URLS.contactForm}>
                Napište nám
              </Button>
            </Box>
          </Box>

          <Box mx={"-15px"} flexGrow={1} display={["block", null, "none"]} boxShadow="one" bg="white.100">
            <Image
              boxShadow="one"
              width="100%"
              display="block"
              maxHeight={["250px", "300px", null, "100%"]}
              src={image?.sourceUrl ?? require("../../assets/images/placeholder/test-image-5.jpg").default}
              objectFit={"cover"}
            />
          </Box>

          <Box
            mx={["-15px", null, 0]}
            pt={[3, null, null, 0]}
            px={["15px", null, 0]}
            maxWidth={["none", null, "285px"]}
            width={["auto", null, 1]}
            bg={["white.100", null, "transparent"]}
          >
            <Heading as={"h4"} fontSize={2} lineHeight={"19px"} fontWeight="semiBold" mb={4} mt={[null, null, "44px", 0]} color="bodyText" uppercase>
              Sídlo společnosti
            </Heading>

            <Text as="address" fontStyle="normal" mb="1em" lineHeight={2}>
              <Text as="strong" fontWeight="semiBold">
                KAPITOL, a.s.
              </Text>
              <br />
              Vlněna 526/3
              <br />
              602 00 Brno
              <br />
              Česká republika
            </Text>

            <Text mb="1em" lineHeight={2}>
              IČ: 60751070
              <br />
              DIČ: CZ699000955
            </Text>

            <Text mb={4} lineHeight={2}>
              Společnost zapsaná v&nbsp;obchodním rejstříku vedeném Krajským soudem v&nbsp;Brně, oddíl B, vložka 1604
            </Text>

            <Link variant="smallBorderTop" target="_blank" href={URLS.companyLegal}>
              VÝPIS Z&nbsp;OBCHODNÍHO REJSTŘÍKU
              <Icon mb={"-1px"} ml={1} icon="icon-next" size={10} color="cta" />
            </Link>
          </Box>
        </Flex>

        <Box flexGrow={1} display={["none", null, "block"]}>
          <Image
            maxWidth="387px"
            mt={{ xl: "-40%" }}
            boxShadow="one"
            width="100%"
            ml={"auto"}
            borderRadius={1}
            display="block"
            src={image?.sourceUrl ?? require("../../assets/images/placeholder/test-image-5.jpg").default}
          />
        </Box>
      </Flex>
    </Container>
  </Wrapper>
);

export default MasterContact;
